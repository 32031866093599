body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* background: linear-gradient(180deg, #FFF2EE 0%, #FFF8FA 100%); */
  background-color: #1D1A2C !important;
  color: #fff !important;
  background-repeat: no-repeat;
}
body,
html {
  height: 100%;

}
body{
   overflow-y: scroll;
}
#root{
  height: 100%;
}
  @media (min-width: 300px){
        body,html{ height: auto} /*>=300的设备*/
    } 
 
    @media (min-width: 1000px) {
        body,html{height: 100%} /*>=1000的设备*/
    } 
   

 